@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Keyframes */
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Font Faces */
@font-face {
  font-family: 'Benton Sans Condensed Bold';
  src: url('./fonts/benton-sans-cond-bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Benton Sans Bold';
  src: url('./fonts/benton-sans-bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Benton Sans Condensed';
  src: url('./fonts/benton-sans-cond-regular.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Benton Sans Book';
  src: url('./fonts/bentonsans-book.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Hatton';
  src: url('./fonts/hatton.otf') format('opentype');
  font-weight: bold;
}

/* Global Styles */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  transition: opacity 300ms ease-in-out;
  position: relative;
}

/* Logo and Menu Items */
h1.logo {
  font-family: 'Hatton', sans-serif;
  color: #232323;
}

.menu-item,
.menu-item:hover {
  font-family: 'Benton Sans Condensed', sans-serif;
  color: #555555;
  text-decoration: none !important;
}

.menu-item:hover {
  color: #232323;
}

/* Fade-in Animation */
.fade-in {
  animation: fadeIn 0.3s ease-in-out;
  opacity: 1;
}

/* About Page */
.about-page {
  margin: 0 auto;
  text-align: center;
}

.about-page__photo {
  position: relative;
  width: 100%;
  height: 100vh;
}

.about-page__image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.about-page__content {
  position: relative;
  z-index: 10;
}

.about-row {
  padding-top: 20px;
  padding-bottom: 20px;
}

.about-page__text {
  padding-left: 15px;
  padding-right: 15px;
  margin: 6px;
  transition: font-size ease-in-out 130ms;
}

h2 {
  font-family: 'Benton Sans Bold', sans-serif;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.4 !important;
  text-rendering: optimizeLegibility;
  color: #000000;
  transition: font-size ease-in-out 130ms;
  display: block;
  text-align: center;
}

h3 {
  font-family: 'Benton Sans Condensed', sans-serif;
  font-size: 24px;
  font-weight:normal;
  line-height: 1.4 !important;
  color: #000000;
  transition: font-size ease-in-out 130ms;
  text-align: center;
}

p.about-para {
  font-size: 14px;
  word-wrap: break-word;
  color: #555555;
}

.spacer {
  height: 1vh;
}

/* Slideshow Items */
.slideshow-items {
  font-family: 'Benton Sans Book', sans-serif;
  color: #fff;
  text-decoration: none !important;
  margin-bottom: 20px;
}

/* See More Button */
.see-more {
  display: inline-block;
  line-height: 1;
  padding: 15px 30px;
  font-family: 'Benton Sans Book', sans-serif;
  font-size: 14px;
  color: #fff;
  text-decoration: none !important;
  border-radius: 2px;
}

/* Disabled Button */
button:disabled {
  cursor: default;
  opacity: 0.3;
}

/* Hide Scrollbar */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.pin_container {
  margin: 0;
  padding: 0;
  width: '80vw';
  display: 'grid';
  grid-template-columns: repeat(auto-fill, 250px);
  grid-auto-rows: 10px;
  position: 'absolute';
  left: '50%';
  transform: 'translateX(-50%)';
  justify-content: center;
  background-color: black;
}

/* Responsive Styles */
.responsive-item {
  width: 100%;
  /* Default to full width */
}

@media (min-width: 640px) {
  .responsive-item {
    width: 50%;
    /* 2 items per row on small screens */
  }

  .contact-header p  {
    font-size: 12px;
  }
}

@media (min-width: 768px) {
  .responsive-item {
    width: 50%;
    /* 3 items per row on medium screens */
  }

  .contact-header p  {
    font-size: 12px;
  }
}

@media (min-width: 1024px) {
  .responsive-item {
    width: 33.33%;
    /* 4 items per row on large screens */
  }

  .about-page__text {
    text-align: center;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }

  .about-page__image {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }

}

@media (min-width: 1280px) {
  .responsive-item {
    width: 33.33%;
    /* 5 items per row on extra large screens */
  }
}

/* Mobile Specific Styles */
@media (max-width: 768px) {
  .about-page__photo {
    height: auto;
    margin-top: -30px;
  }

  .about-page__image {
    object-position: 79% center;
    height: calc(100vh - 50px);
  }

  .contact-header {
    font-size: 12px;
  }
}

/* Desktop Specific Styles */
@media (min-width: 769px) {

  .about-page__photo,
  .about-page__image {
    height: auto;
  }

  .contact-header p {
    font-size: 16px;
  }
}

.auto-scroll {
  position: relative;
  overflow-x: hidden;
}

.auto-scroll::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  animation: scroll 30s linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-1 * var(--scroll-distance)));
  }
}

.stay-in-touch {
  font-family: 'Benton Sans Book', sans-serif;
  font-size: 16px;
}

.stay-in-touch__form {
  margin: 8px;
}

.stay-in-touch__email-container {
  min-width: 300px;
}

.stay-in-touch__email {
  padding: 16px;
  width: 100%;
  height: 46px;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  padding: 16px;
  font-size: 16px;
  line-height: 16px;
  background: transparent;
  border-color: white;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.stay-in-touch__email::placeholder {
  opacity: 0.5;
  font-size: 14px;
}

.stay-in-touch__email:focus {
  outline: none;
}

.stay-in-touch__submit-container {
  display: inline-block;
    width: auto;
    margin: 8px;
}

.stay-in-touch__submit-button {
  display: inline-block;
  background-color: white;
  color: black;
  width: 100%;
  padding: 15px 30px;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  font-size: 14px;
    line-height: 1;
    appearance: none;
    white-space: nowrap;
    text-decoration: none;
    cursor: pointer;
}

div.contact-header {
  line-height: 150%;
}

.contact-header h2 {
  color: #fff;
  font-family: Benton Sans Bold;
  line-height: 1.4 !important;
  font-size: 24px;
  font-weight: bold;
}

.contact-header p {
  color: #fff;
  font-family: Benton Sans Book;
  line-height: 1.7 !important;
}

.contact-header div strong {
  color: #fff;
}

.contact-us input::placeholder, .contact-us textarea::placeholder {
  font-family: 'Benton Sans Book', sans-serif;
  font-size: 12px;
  color: #fff;
  opacity: 0.7;
}